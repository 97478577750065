import React from 'react';
import ReactDOM from 'react-dom';
import Particles from 'react-particles-js';
import svgDirt from './dirt.svg';


class ParticleBackground extends React.Component {
    render(){
    return (
        <Particles
        style={{ position: "absolute" }}
            height="100%"
            width="100%"
            params={{
	    "particles": {
	        "number": {
	            "value": 5,
	            "density": {
	                "enable": true,
	                "value_area": 700
	            }
	        },
	        "line_linked": {
	            "enable": false
	        },
	        "move": {
	            "speed": 1,
	            "out_mode": "out"
	        },
	        "shape": {
	            "type": [
	                "image",
	                "circle"
	            ],
	            "image": [
	                {
	                    "src": "https://etherdirt.com/holdingdirt.png",
	                    "height": 50,
	                    "width": 60
	                },
	            ]
               
	        },
	        "color": {
	            "value": "#CCC"
	        },
	        "size": {
	            "value": 30,
	            "random": false,
	            "anim": {
	                "enable": true,
	                "speed": 3,
	                "size_min": 10,
	                "sync": false
	            }
	        }
	    },
	    "retina_detect": false
	}} />
    )}
}

export default ParticleBackground;
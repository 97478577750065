import React, { Component } from "react";
import EtherDirt from "./contracts/EtherDirt.json";
import getWeb3 from "./getWeb3";
import Particles from 'react-particles-js';
import ParticleBackground from './particleBackground.js';
import layoutStyles from './styles/layout.css';
// import Form from 'react-bootstrap/Form'

import "./App.css";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Modal = props => {
  if (!props.show){
    return null;
  }
  return (
    <div className="modal">
      <div className="modal-content">
        <div id="userInfo"><b>Welcome to the dirt management console! </b> 
        You can only use the function below if you own dirt.</div>  
        {/*
        <h4>Sell Dirt</h4>
        <div className="form-group">
          <input className="form-control" rows="1" id="selldirtid" placeholder="Dirt ID (eg. 54)" value={props.sellDirtId} onChange={props.sellDirtIdOnChange}></input>
        </div>
        <div className="form-group">
          <input className="form-control" rows="1" id="selldirtprice" value={props.sellDirtPrice} onChange={props.sellDirtPriceOnChange} placeholder="Price of dirt in ETH - can enter decimals (eg. 0.0123)"></input>
        </div>
        <div id="changesell"><button className="buttonFull" disabled={props.sellDisabled} onClick={props.sellOnClick}>Sell Dirt</button></div>
        */}
        <h4>Transfer Dirt</h4>
        <div className="form-group">
          <input className="form-control" rows="1" id="transferdirtid" value={props.transferDirtId} onChange={props.transferDirtIdOnChange} placeholder="Dirt ID (eg. 54)"></input>
        </div>
        <div className="form-group">
          <input className="form-control" rows="1" id="transferdirtwallet" value={props.transferDirtAddress} onChange={props.transferDirtAddressOnChange} placeholder="Ethereum address to transfer dirt to"></input>
        </div>
        <div id="changetransfer"><button className="buttonFull" disabled={props.transferDisabled} onClick={props.transferOnClick}> Transfer Dirt</button></div>

        {/*
        <h4>Take Dirt Off Market (Delist)</h4>
        <div className="form-group">
          <input className="form-control" rows="1" id="takeoffmarketid" value={props.dontSellDirtId} onChange={props.dontSellDirtIdOnChange} placeholder="Dirt ID (eg. 54)"></input>
        </div>
        <div id="takeoffmarket"><button className="buttonFull" disabled={props.dontSellDirtDisabled} onClick={props.dontSellDirtOnClick}>Take Dirt Off Market</button></div>
        */}

        <button className="returnToWebsite" onClick={props.onClose()}>Return to website</button>
      </div>
    </div>
  )
}

class App extends Component {
  state = { 
    web3: null, 
    accounts: null, 
    contract: null,
    dirtInfo: [],
    latestDirtId: 0,
    modalIsOpen: false,
    sellDirtId: "",
    sellDirtPrice: "",
    transferDirtId: "",
    transferDirtAddress: "",
    dontSellDirtId: "",
    sellDisabled: true,
    transferDisabled: true,
    dontSellDirtDisabled: true
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  sellDirtIdOnChange(event) {
    this.setState({ sellDirtId: event.target.value });
    this.setSellDisabled(event.target.value, -1);
  }

  sellDirtPriceOnChange(event) {
    this.setState({ sellDirtPrice: event.target.value });
    this.setSellDisabled(-1, event.target.value);
  }

  setSellDisabled(sellDirtIdValue, sellDirtPriceValue) {
    const { sellDirtId, sellDirtPrice } = this.state;

    let sellDirtIdCheck = sellDirtPriceValue == -1 ? sellDirtIdValue : sellDirtId;
    let sellDirtPriceCheck = sellDirtIdValue == -1 ? sellDirtPriceValue : sellDirtPrice;

    this.setState({ sellDisabled: sellDirtIdCheck.length == 0 || sellDirtPriceCheck.length == 0 })
  }

  sellOnClick() {
    const { sellDirtId, sellDirtPrice, contract, web3, accounts } = this.state;
    this.sellDirt(contract, web3, accounts, sellDirtId, sellDirtPrice);
  }

  transferOnClick() {
    const { transferDirtId, transferDirtAddress, contract, web3, accounts } = this.state;
    this.giftDirt(contract, web3, accounts, transferDirtId, transferDirtAddress);
  }

  dontSellDirtOnClick() {
    const { dontSellDirtId, contract, web3, accounts } = this.state;
    this.dontSellDirt(contract, web3, accounts, dontSellDirtId);
  }

  transferDirtIdOnChange(event) {
    this.setState({ transferDirtId: event.target.value });
    this.setTransferDisabled(event.target.value, -1);
  }

  transferDirtAddressOnChange(event) {
    this.setState({ transferDirtAddress: event.target.value });
    this.setTransferDisabled(-1, event.target.value);
  }
  
  setTransferDisabled(transferDirtIdValue, transferDirtAddressValue) {
    const { transferDirtId, transferDirtAddress } = this.state;

    let transferDirtIdCheck = transferDirtAddressValue == -1 ? transferDirtIdValue : transferDirtId;
    let transferDirtAddressCheck = transferDirtIdValue == -1 ? transferDirtAddressValue : transferDirtAddress;

    this.setState({ transferDisabled: transferDirtIdCheck.length == 0 || transferDirtAddressCheck.length == 0 })
  }

  dontSellDirtIdOnChange(event) {
    this.setState({ dontSellDirtId: event.target.value });
    this.setDontSellDirtDisabled(event.target.value);
  }
  
  setDontSellDirtDisabled(dontSellDirtIdValue) {
    this.setState({ dontSellDirtDisabled: dontSellDirtIdValue.length == 0 })
  }

  componentDidMount = async () => {
    try {
      // Ethereum Networks
      const networks = [
        [""],                                 // 0
        ["", "mainnet"],                      // 1
        [""],                                 // 2
        ["", "", "", "ropsten"],              // 3
        ["", "", "", "", "rinkeby"],          // 4 
        ["", "", "", "", "", "goerli"]        // 5
      ];

      // Get network provider and web3 instance.
      const web3 = await getWeb3();
      // Use web3 to get the user's accounts.
      const accounts = await web3.eth.getAccounts();
      // Get the contract instance.
      const chainId = await web3.eth.getChainId();
      const networkId = await web3.eth.net.getId();

      // Get the contract instance.
      // const networkId = await web3.eth.net.getId();
      // const deployedNetwork = SimpleStorageContract.networks[networkId];

      const gasPrice = await web3.eth.getGasPrice();

      const instance = new web3.eth.Contract(
        EtherDirt.abi,
        "0xF3138B866A8A4eBbAD99080602fCE22bF0103AB3"
      );

      // Set web3, accounts, and contract to the state, and then proceed with an
      // example of interacting with the contract's methods.
      this.setState({ web3, accounts, contract: instance, network: networks[chainId][networkId]}, this.runExample);
    } catch (error) {
      // Catch any errors for any of the above operations.
      // alert(
      //   `Failed to connect to Metamask. Please check  pending request in your Metamask extension.`,
      // );
      console.error(error);
    }
  };

  runExample = async () => {
    const { accounts, contract } = this.state;
    // Get the value from the contract to prove it worked.
    let latestDirtId = await contract.methods.latestNewDirtForSale().call();
    // Get Non-Virgin Dirt Info
    let dirtInfo = [];

    // for (let i = 0; i < latestDirtId; i++) {
    //   let currDirt = await contract.methods.getDirtInfo(i).call();
    //   let owner = await contract.methods.ownerOf(i).call();
    let ownersAsync = [];
    for (let i = 0; i < latestDirtId; i++) {
      ownersAsync.push(contract.methods.ownerOf(i).call());
    }

    try {
      const owners = await Promise.all(ownersAsync);
      dirtInfo = owners;
    } catch (err) {
      console.log(err);

      try {
        const owners = await Promise.all(ownersAsync);
        dirtInfo = owners;
      } catch (err) {
        console.log(err);
      }
    }


    // }

    // Get Virgin Dirt Info
    let latestDirtTokenURI = "https://gateway.pinata.cloud/ipfs/QmeftMr8VGjUzFNakBkHX6dpQbua17uADQrNbv5aBzRPv7/id/" + latestDirtId;
    let latestDirtInfo = await contract.methods.getDirtInfo(latestDirtId).call();

    // Set Virgin Dirt token URL
    fetch(latestDirtTokenURI)
        .then(response => response.json())
        .then((jsonData) => {
          latestDirtInfo["tokenURI"] = jsonData.image;
          this.setState({ latestDirtInfo });
        })
        .catch((error) => {
          console.error(error)
        })

    // Update state with the result.
    this.setState({ latestDirtId, dirtInfo, latestDirtInfo });
  };

  // Already comes in gwei
  buyDirt = function (contract, web3, accounts, gweiPrice, dirtId) {
    contract.methods.buyDirt(dirtId).send({
      from: accounts[0], 
      value: gweiPrice     
    });
  }

  sellDirt = function (contract, web3, accounts, dirtIndex, price) {
    const gweiPrice = web3.utils.toWei(price, 'ether');
    contract.methods.sellDirt(dirtIndex, gweiPrice).send({
      from: accounts[0]
    });
  }

  dontSellDirt = function (contract, web3, accounts, dirtIndex) {
    contract.methods.dontSellDirt(dirtIndex).send({
      from: accounts[0]
    });
  }

  giftDirt = function (contract, web3, accounts, dirtIndex, address) {
    contract.methods.giftDirt(dirtIndex, address).send({
      from: accounts[0]
    });
  }

  generateDirtCard(buyDirt, state, owner, dirtId) {
    return (
      <div key={"cardid" + dirtId} className={ (state.accounts[0] === owner) ? "ownerDirt" : "inactiveDirt" }>
        <div className="contentContainer">
        {(state.accounts[0] === owner) ? <h5 className="ownerLabel">You own this dirt</h5> : null}
        <img key={"cardimageid" + dirtId} src={"https://etherdirt.com/ED/" + dirtId + ".png"} width="100%" align="middle" />
        <h5>Dirt ID {dirtId}</h5>
        <h5><u>
        <a href={"http://etherscan.io/address/" + owner} target="_blank">OWNER</a>
        </u></h5>
        { <p><a target="_blank" href={"https://opensea.io/assets/0xf3138b866a8a4ebbad99080602fce22bf0103ab3/" + dirtId}><button className="buttonPrimary">View On OpenSea</button></a></p>
        }
        </div>
      </div>
    );
  }

 
  render() {
    const correctNetwork = this.state.network === "mainnet";
    const networkSet = this.state.network !== "" && (this.state.accounts !== null && this.state.accounts.length > 0);
    const virginsAvailable = this.state.latestDirtId < 100 && this.state.latestDirtInfo != null;

    if (true) {
      let dirtCards;
      if (this.state.dirtInfo !== undefined) {
        dirtCards = this.state.dirtInfo.map((owner, index) =>
           this.generateDirtCard(this.buyDirt, this.state, owner, index)
        );
      } else {
        dirtCards = <div>Loading...</div>
      }

      return (
      <>


        <div className="maintable">
          <h3>Ether Dirt - Pet Dirt On The Blockchain</h3>
          <p>Launched in 2021, EtherDirt was one of MANY derivative crypto collectible
            NFT-type projects that flooded the Ethereum blockchain, and was launched far too late to be of any actual value. 
            On the plus side, only 100 piles of dirt will ever be available, and each new
            virgin pile of dirt gets more and more expensive. This game is built entirely 
            on the Ethereum blockchain, with a decentralized smart contract 
            (view it <a href="https://etherscan.io/address/0xf3138b866a8a4ebbad99080602fce22bf0103ab3" target="_blank">here</a>) used
             to manage everything including the buying and selling of dirt (though this website is still necessary so you can see 
            pretty pictures of the dirt).
          </p>
          <div id="maininfotext">
            <p>
              <b>
                <span className="highlight">STATUS: </span>
                {(correctNetwork && networkSet) &&
                  <u>You have connected your Metamask wallet, excellent stuff! You are ready to start buying and selling dirt!</u>
                }
                {(!correctNetwork && networkSet) &&
                  <u>You are currently connected to the {this.state.network} network. Switch to the Ethereum mainnet to start trading dirt.
                  </u>
                }
                {(!networkSet) &&
                  <u>You are not connected with Metamask. Connect with Metamask on Ethereum Mainnet to start trading piles of dirt.
                  </u>
                }
              </b>
            </p>
          </div>
          <p>
            {/* <b>Own dirt? <button className="dirtManagementButton" onClick={e=>this.openModal()}> Go to the dirt management console allowing you to transfer your dirt.</button>
             </b> */}
          </p>
          <Modal 
            onClose={e => this.closeModal.bind(this)} 
            show={this.state.modalIsOpen} 
            sellDirtIdOnChange={this.sellDirtIdOnChange.bind(this)}
            sellDirtPriceOnChange={this.sellDirtPriceOnChange.bind(this)}
            sellDirtPrice={this.state.sellDirtPrice}
            sellDirtId={this.state.sellDirtId}
            sellDisabled={this.state.sellDisabled}

            transferDirtIdOnChange={this.transferDirtIdOnChange.bind(this)}
            transferDirtAddressOnChange={this.transferDirtAddressOnChange.bind(this)}
            transferDirtAddress={this.state.transferDirtAddress}
            transferDirtId={this.state.transferDirtId}
            transferDisabled={this.state.transferDisabled}

            dontSellDirtId={this.state.dontSellDirtId}
            dontSellDirtIdOnChange={this.dontSellDirtIdOnChange.bind(this)}
            dontSellDirtDisabled={this.state.dontSellDirtDisabled}

            sellOnClick={this.sellOnClick.bind(this)}
            transferOnClick={this.transferOnClick.bind(this)}
            dontSellDirtOnClick={this.dontSellDirtOnClick.bind(this)}

          />
          <p>etherdirtcom@gmail.com | 
          <a href="https://discord.gg/CyybDCC7"> Discord</a> | 
          <a href="https://twitter.com/etherdirt"> Twitter</a> | 
            <a href="https://twitter.com/etherdirtprice"> Latest dirt prices</a> |
            <a href="https://opensea.io/collection/etherdirt"> OpenSea</a>
          </p>
          {/* <div id="connectbutton"><button id="enableEthereumButton" onClick={() => {}}>CONNECT TO METAMASK</button></div> */}
          <h4>Latest Virgin Dirt [Never Before Sold]</h4>
          {/* <b>All 100 dirt has been sold</b> . You can purchase non-virgin dirt below, if they have been listed for sale by their owners. Beware of any other projects claiming to sell dirt NFT\'s, which may either be scams and/or using buggy exploited contracts (e.g. \"etherdirtreal\").*/}
          <p>Dirt selling is open! Piles of dirt are listed at increasing prices determined by the equation <b>price = (0.001 + 0.001*(DirtID)^2)/8</b>, starting at 0.00025Ξ, until all 100 dirt piles have been sold. Owners can list their piles of dirt for sale or transfer to other addresses immediately after purchase.</p>
          {virginsAvailable &&
            <div className="virginDirtContainer">
            
              <div className="contentContainer">
                <h5 className="priceLabel">Current Price: {this.state.web3.utils.fromWei(this.state.latestDirtInfo['2'], 'ether')}Ξ</h5>
                <img key="exciting key" src={this.state.latestDirtInfo["tokenURI"]} width="100%"  align="middle" />
                <h5>Dirt ID {this.state.latestDirtId}</h5>
                <h5><u>
                <a href={"http://etherscan.io/address/0x0000000000000000000000000000000000000000"} target="_blank">NO OWNER</a>
                </u></h5>
                { <p><button href="#!" 
                    onClick={() => this.buyDirt(this.state.contract, this.state.web3, this.state.accounts, this.state.latestDirtInfo['2'], this.state.latestDirtId)} id="" className="buttonPrimary">Buy Virgin Dirt</button></p> }
              </div>
              
            </div>
          }

          <br/>

          <h4>All Active (USED) Dirt</h4>
      
          <div className="useddirtinfo"><p>All the dirt below have been purchased at least once - owners can list them for sale, if they like.</p></div>
          <br/>
          <div>
          {this.state.dirtInfo.length === 0 && <>
          <b>Loading...</b>

          </>
          }
          {this.state.dirtInfo.length !== 0 && dirtCards}
          </div>
        </div>

       <ParticleBackground/>
        </>
      );
    }


    {/* if (!this.state.web3) {
      return <div>Loading Web3, accounts, and contract...</div>;
    }
    return (
      <div className="App">
        <h1>Good to Go!</h1>
        <p>Your Truffle Box is installed and ready.</p>
        <h2>Smart Contract Example</h2>
        <p>
          If your contracts compiled and migrated successfully, below will show
          a stored value of 5 (by default).
        </p>
        <p>
          Try changing the value stored on <strong>line 42</strong> of App.js.
        </p>
        <button onClick={() => this.buyDirt(this.state.contract, this.state.web3, this.state.accounts)}>Buy Dirt</button>
      </div>
    ); */}
  }
}

export default App;
